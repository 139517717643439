import moment from "moment";

import { Project, Schedule } from "API";
import { getStatusText, getStatusInitial } from "utils/status";

export type ScheduleDate = {
  hasData: boolean;
  type: string;
  text: string;
  initial: string;
  color: string;
};

// ステータスを出力する。出荷はスケジュールの出荷方法を出力する。
export function getCustomStatusText(
  isInitial: boolean,
  status: string,
  schedule: Schedule
) {
  let retVal: string = "";
  if (status === "SHIP") {
    retVal = schedule.shipType ?? "";
  } else if (!isInitial && getStatusText(status) !== "?") {
    retVal = getStatusText(status);
  } else if (isInitial && getStatusInitial(status) !== "?") {
    retVal = getStatusInitial(status);
  } else {
    switch (status) {
      case "BEFORESTOCK":
        retVal = schedule.beforeStockingName ?? "";
        break;
      case "BEFOREPACK":
        retVal = schedule.beforePackagingName ?? "";
        break;
      case "BEFORESHIP":
        retVal = schedule.beforeShippingName ?? "";
        break;
      case "BEFORECUT":
        retVal = schedule.beforeCutName ?? "";
        break;
      case "AFTERCUT":
        retVal = schedule.afterCutName ?? "";
        break;
      default:
        retVal = status;
        break;
    }
  }
  return isInitial ? retVal.substring(0, 1) : retVal.substring(0, 3);
}

export function getFillerColor(status: string) {
  switch (status) {
    case "STOCK":
      return "#29BF12";
    case "PACK":
      return "#3E6EB3";
    case "SHIP":
      return "#D18643";
    case "CUT":
      return "#bd3993";
    case "":
      return "transparent";
    default:
      return "#888";
  }
}

export const getScheduleDates = (schedule: Schedule, dates: string[]) => {
  return dates.reduce((acc, date) => {
    /** 該当フェーズ名 */
    let phase = "";

    /** スケジュール項目名 名前,日付のオブジェクト */
    const phaseArray: Array<{ name: string; date: string | null | undefined }> =
      [
        { name: "BEFORESTOCK", date: schedule.beforeStockingDate }, // 入荷前
        { name: "STOCK", date: schedule.stockingDate }, // 入荷
        { name: "BEFOREPACK", date: schedule.beforePackagingDate }, // 梱包前
        { name: "PACK", date: schedule.packagingDate }, // 梱包
        { name: "BEFORESHIP", date: schedule.beforeShippingDate }, // 出荷前
        { name: "SHIP", date: schedule.shippingDate }, // 出荷
        { name: "BEFORECUT", date: schedule.beforeCutDate }, // CUT前
        { name: "CUT", date: schedule.cutDate }, // CUT
        { name: "AFTERCUT", date: schedule.afterCutDate }, // CUT後
      ];

    // ////////////////////////////////////////
    // ★基準日の判定★

    // その日付で一番最後にくるフェーズ名を取得
    for (let i = 0; i < phaseArray.length; i++) {
      if (phaseArray[i].date === date) {
        phase = phaseArray[i].name;
      }
    }

    // スケジュールで指定した日付に該当する場合はここから返す
    if (phase) {
      return {
        ...acc,
        [date]: {
          hasData: !!phase,
          type: phase,
          text: phase && getCustomStatusText(false, phase, schedule),
          initial: phase && getCustomStatusText(true, phase, schedule),
          color: phase && getFillerColor(phase),
          m3: schedule.m3 || 0,
          case: schedule.case || 0,
        },
      };
    }

    // ////////////////////////////////////////
    // ★基準日間のデータ判定★

    // スケジュール日の中間かどうかを判定する(～ING)
    const target = moment(date);
    // 案件タイプ=その他の場合は梱包日がないため、別に判定する
    const isPkg = !!schedule.packagingDate;
    let fromPhase = "";
    let fromDate = "";
    // どのスケジュールに該当するのか確認
    for (let i = 0; i <= phaseArray.length; i++) {
      if (!phaseArray[i]?.date || !phaseArray[i]?.name) continue;
      // 始まりのデータ。最初はデータを登録して終わり
      if (!fromDate) {
        fromPhase = phaseArray[i].name;
        fromDate = phaseArray[i].date ?? "";
        continue;
      }
      // その他案件の場合は梱包前・梱包は梱包フェーズがないため処理しない
      if (
        !isPkg &&
        (phaseArray[i].name === "PACK" || phaseArray[i].name === "BEFOREPACK")
      ) {
        continue;
      }
      // 出荷方法がバン以外の場合はCUT以降の処理をしない
      if (schedule.shipType !== "バン" && phaseArray[i].name === "CUT") {
        break;
      }
      // 対象日が被っているかを確認。被っていればそのフェーズの範囲。
      if (target.isBetween(fromDate, phaseArray[i].date, "day", "[]")) {
        if (fromPhase === "BEFORESTOCK") {
          // StockingとbeforeStockingの間はbeforeStockingの領域にする
          phase = fromDate;
        } else {
          // 上記以外は次のフェーズの色を塗る
          phase = phaseArray[i].name;
        }
      }
      // 次のフェーズが空の場合もあるため今回のフェーズを取っておく
      fromPhase = phaseArray[i].name;
      fromDate = phaseArray[i].date ?? "";
    }

    return {
      ...acc,
      [date]: {
        hasData: false,
        type: phase,
        text: "",
        initial: "",
        color: phase && getFillerColor(phase),
      },
    };
  }, {});
};

export const ganttifyProject = (project: Project, dates: string[]) => {
  if (!project.schedules) return project;
  const schedules = project.schedules?.items.reduce((acc, schedule) => {
    if (!schedule) return acc;
    const scheduleDates = getScheduleDates(schedule, dates);
    (acc as any).push({ ...schedule, ...scheduleDates });
    return acc;
  }, []);
  project.schedules.items = schedules;
  return project;
};
