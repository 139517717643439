import moment, { Moment } from "moment";

import type { VisibleKeyDates } from "./types";

import { Schedule } from "API";

/**
 * Returns an object with the start and end dates for the focused schedule.
 * @param {Schedule} schedule - The schedule of interest.
 * @param {string[]} dates - The array of date strings.
 * @param {KeyDatesVisibility} visibleKeyDates - The visibility of the key dates.
 * @returns {{startDate: Date, endDate: Date}} - An object containing the start and end dates.
 */
export function getFocusedDateRange(
  schedule: Schedule,
  dates: string[],
  visibleKeyDates: VisibleKeyDates
): { startDate: Date; endDate: Date } {
  const keyDateMoments = getKeyDateMoments(schedule);
  const [initialStartDate, initialEndDate] = getInitialDateRange(dates);

  const startDate = getNewStartDate(
    initialStartDate,
    keyDateMoments,
    visibleKeyDates
  );
  const endDate = getNewEndDate(
    initialEndDate,
    keyDateMoments,
    visibleKeyDates
  );

  const adjustedDates = adjustDateRange(startDate, endDate);

  return {
    startDate: adjustedDates.startDate.toDate(),
    endDate: adjustedDates.endDate.toDate(),
  };
}

/**
 * Converts schedule key dates in a schedule to Moment.js objects.
 * @param {Schedule} keyDate - The key dates of the schedule.
 * @returns {Record<string, Moment>} - An object with key date names and their Moment.js representations.
 */
function getKeyDateMoments(keyDate: Schedule): Record<string, Moment> {
  return {
    beforeStock: moment(keyDate.beforeStockingDate),
    stock: moment(keyDate.stockingDate),
    beforePkg: moment(keyDate.beforePackagingDate),
    pkg: moment(keyDate.packagingDate),
    beforeShip: moment(keyDate.beforeShippingDate),
    ship: moment(keyDate.shippingDate),
    beforeCut: moment(keyDate.beforeCutDate),
    cut: moment(keyDate.cutDate),
    afterCut: moment(keyDate.afterCutDate),
  };
}

/**
 * Extracts the initial start and end dates from the given date strings array.
 * @param {string[]} dates - The array of date strings.
 * @returns {[Moment, Moment]} - A tuple containing the initial start and end dates as Moment.js objects.
 */
function getInitialDateRange(dates: string[]): [Moment, Moment] {
  return [moment(dates[0]), moment(dates[dates.length - 1])];
}

/**
 * Determines the new start date based on key date visibility and validity.
 * @param {Moment} initialStartDate - The initial start date.
 * @param {Record<string, Moment>} keyDateMoments - The key date moments.
 * @param {KeyDatesVisibility} visibleKeyDates - The visibility of the key dates.
 * @returns {Moment} - The new start date.
 */
function getNewStartDate(
  initialStartDate: Moment,
  keyDateMoments: Record<string, Moment>,
  visibleKeyDates: VisibleKeyDates
): Moment {
  if (
    keyDateMoments.beforeStock.isValid() &&
    visibleKeyDates.beforeStockingDate
  ) {
    // 入荷前
    return keyDateMoments.beforeStock;
  } else if (keyDateMoments.stock.isValid() && visibleKeyDates.stockingDate) {
    // 入荷
    return keyDateMoments.stock;
  }
  // 画面表示範囲の開始日
  return initialStartDate;
}

/**
 * Determines the new end date based on key date visibility and validity.
 * @param {Moment} initialEndDate - The initial end date.
 * @param {Record<string, Moment>} keyDateMoments - The key date moments.
 * @param {KeyDatesVisibility} visibleKeyDates - The visibility of the key dates.
 * @returns {Moment} - The new end date.
 */
function getNewEndDate(
  initialEndDate: Moment,
  keyDateMoments: Record<string, Moment>,
  visibleKeyDates: VisibleKeyDates
): Moment {
  if (keyDateMoments.afterCut.isValid() && visibleKeyDates.afterCutDate) {
    // CUT後
    return keyDateMoments.afterCut;
  } else if (keyDateMoments.cut.isValid() && visibleKeyDates.cutDate) {
    // CUT
    return keyDateMoments.cut;
  } else if (
    keyDateMoments.beforeCut.isValid() &&
    visibleKeyDates.beforeCutDate
  ) {
    // CUT前
    return keyDateMoments.beforeCut;
  } else if (keyDateMoments.ship.isValid() && visibleKeyDates.shippingDate) {
    // 出荷
    return keyDateMoments.ship;
  }
  // 画面表示範囲の終了日
  return initialEndDate;
}

/**
 * Adjusts the start and end dates to ensure a minimum range of 7 days.
 * @param {Moment} startDate - The current start date.
 * @param {Moment} endDate - The current end date.
 * @returns {{startDate: Moment, endDate: Moment}} - An object containing the adjusted start and end dates.
 */
function adjustDateRange(
  startDate: Moment,
  endDate: Moment
): { startDate: Moment; endDate: Moment } {
  const diff = endDate.diff(startDate, "d");

  if (diff < 7) {
    const adjustment = (7 - diff) / 2;
    endDate = endDate.add(adjustment, "d");
    startDate = startDate.subtract(adjustment, "d");
  }

  return { startDate, endDate };
}
