import { API } from "aws-amplify";

import { Material, Product, SummarizedMaterialOutput } from "API";
import {
  productsByProjectId,
  productsByScheduleId,
  materialsByGroupId,
  listSummarizedMaterials,
} from "graphql/queries";

export async function getProductDataByScheduleId(
  scheduleId: string
): Promise<Product[]> {
  const p = (
    await API.graphql({
      query: productsByScheduleId,
      variables: { scheduleId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.productsByScheduleId;

  const products: Product[] = p.items.map((item: any) => {
    return {
      __typename: "Product",
      ...item,
    };
  });

  return Promise.resolve(products);
}

export async function getProductDataByProjectId(
  projectId: string
): Promise<Product[]> {
  const p = (
    await API.graphql({
      query: productsByProjectId,
      variables: { projectId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.productsByProjectId;

  const products: Product[] = p.items.map((item: any) => {
    return {
      __typename: "Product",
      ...item,
    };
  });

  return Promise.resolve(products);
}

export async function listMaterials(groupId: string): Promise<Material[]> {
  const materials = (
    await API.graphql({
      query: materialsByGroupId,
      variables: { groupId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.materialsByGroupId;

  return materials;
}

export async function listSummarizedMaterialsQuery(
  groupId: string,
  materialTypeId: string,
  startDate: string,
  endDate: string
): Promise<SummarizedMaterialOutput[]> {
  let nextToken: string | null = null;
  const results: SummarizedMaterialOutput[] = [];
  let index = 1;

  do {
    const response = await API.graphql({
      query: listSummarizedMaterials,
      variables: {
        input: {
          groupId,
          materialTypeId,
          startDate,
          endDate,
        },
        nextToken,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const data: any = response.data.listSummarizedMaterials;
    results.push(
      ...data.items.map((item: any) => ({
        ...item,
        index: index++,
      }))
    );
    nextToken = data.nextToken;
  } while (nextToken);

  return results;
}
